import animateScrollTo from 'animated-scroll-to'
import gaWrapper from './ga-events'

export default class OverlayNavigation {
    constructor(options) {
        this.$overlay = options.overlay || document.querySelector('.menu-overlay')
        this.$menuBtns = options.closeBtn || document.querySelectorAll('.menu-burger')
        this.$menuItems = options.menuItems || document.querySelectorAll('.menu-overlay__list__item')
        this.$menuLinks = this.$overlay.querySelectorAll('.menu-overlay__list__item a[data-anchor]')

        this.isOpened = false
        this.isAnimating = false
        this.supportedTransitionEnd = false

        this.init()
    }

    init() {

        // Check for transitionend event support
        const transitions = {
            'transition': 'transitionend',
            'WebkitTransition': 'webkitTransitionEnd',
            'MozTransition': 'transitionend',
            'OTransition': 'otransitionend'
        }
        let elem = document.createElement('div');
        for(let t in transitions) {
            if(typeof elem.style[t] !== 'undefined'){
                this.supportedTransitionEnd = transitions[t];
                break;
            }
        }


        // If we have support, then on transitionend handle closing the overlay, 
        // and marking the end of animation
        if(this.supportedTransitionEnd) {
            this.$overlay.addEventListener(this.supportedTransitionEnd, () => {
                if(!this.isOpened) {
                    this.$overlay.style.display = 'none'
                }
                this.isAnimating = false
            })
        }
        
        // Toggle menu for the two stated of the menu buttons
        for(let $menuType of this.$menuBtns) {
            $menuType.addEventListener('click', () => {
                if(!this.isAnimating) {
                    this.toggleMenu()
                }
            })
        }

        for(let $link of this.$menuLinks) {
            $link.addEventListener('click', (e) => {
                e.preventDefault()
                let targetLabel = $link.getAttribute("href")
                let targetElement = document.querySelector(`[data-section-id='${targetLabel}']`)
                animateScrollTo(targetElement);
                this.toggleMenu()
            })
        }
    }

    toggleMenu() {
        this.supportedTransitionEnd ? this.isAnimating = true : this.isAnimating = false

        if(!this.isOpened) {
            this.$overlay.style.display = 'flex'
            

            // Necessary for queuing
            setTimeout(() => {
                this.isOpened = true
                this.$overlay.classList.add('menu-overlay--shown') 

                // Animation delay is set with CSS transition-delay property
                for(let $menuItem of this.$menuItems) {
                    $menuItem.classList.add('menu-overlay__list__item__shown')
                }
            }, 50)
            gaWrapper('Menu Overlay Interaction', 'Mobile Menu', 'Click', 'Open')

        } else {
            this.isOpened = false

            for(let $menuItem of this.$menuItems) {
                $menuItem.classList.remove('menu-overlay__list__item__shown')
            }
            this.$overlay.classList.remove('menu-overlay--shown')

            // Fallback for no transitionend support
            if(!this.supportedTransitionEnd && !this.isOpened) {
                this.$overlay.style.display = 'none'
            }
            gaWrapper('Menu Overlay Interaction', 'Mobile Menu', 'Click', 'Close')
        }

    }
}