require('./scss/main.scss')


// Infographics images
for(let i = 1; i <= 5; i++) {
    require(`./res/infographics/ig_${i}.png`)
    require(`./res/infographics/ig_xl_${i}.png`)
}

// Logo colections
require('./res/PROTV-logo.png')
require('./res/RECORDER-logo.png')
require('./res/TNR-logo.png')
require('./res/EUROPAFM-logo.png')
require('./res/HOTNEWS-logo.png')
require('./res/Capra_video_bg-min.jpg')
require('./res/Capra_video_bg_HD-min.jpg')
require('./res/video_capra_thumb-min.png')
require('./res/capra-fb.png')


// 360 views
for(let i = 1; i <= 36; i++) {
    require(`./res/protv-360-jpg/protv_${i}.jpg`)
    require(`./res/protv-360-jpg/protv_${i}-xs.jpg`)
}

for(let i = 1; i <= 36; i++) {
    require(`./res/hotnews-360-jpg/hotnews_${i}.jpg`)
    require(`./res/hotnews-360-jpg/hotnews_${i}-xs.jpg`)
}

for(let i = 1; i <= 36; i++) {
    require(`./res/europafm-360-jpg/europafm_${i}.jpg`)
    require(`./res/europafm-360-jpg/europafm_${i}-xs.jpg`)
}

for(let i = 1; i <= 36; i++) {
    require(`./res/recorder-360-jpg/recorder_${i}.jpg`)
    require(`./res/recorder-360-jpg/recorder_${i}-xs.jpg`)
}

for(let i = 1; i <= 36; i++) {
    require(`./res/tnr-360-jpg/tnr_${i}.jpg`)
    require(`./res/tnr-360-jpg/tnr_${i}-xs.jpg`)
}

require('intersection-observer')
import * as Hammer from 'hammerjs';
import FadeSlider from './js/fadeSlider'
import SectionTransition from './js/section-transition'
import OverlayNavigation from './js/navigation'
import Slider360 from './js/360-slider'
import gaWrapper from './js/ga-events'

import lozad from 'lozad'

import Rellax from 'rellax'
import './js/header'
import './js/video'
import './js/brading-animation'

// Lazy loading static images
window.lozadObserver = lozad()
lozadObserver.observe()

document.addEventListener('DOMContentLoaded', function() {
    if(window.innerWidth > 992 && window.innerHeight < window.innerWidth) {
        let $sections = document.querySelectorAll('section.section')
        if($sections.length) {
            var sectionTransition = new SectionTransition({})
        }
    }    
})

window.addEventListener('load', () => {
    
    if(window.innerWidth > 992 && window.innerHeight < window.innerWidth) {
        // var sectionTransition = new SectionTransition({})
    } else {

        // Menu overlay
        var overlayNavigation = new OverlayNavigation({})

        let $rellaxElement = document.querySelector('.rellax')
        if($rellaxElement) {
            var rellax = new Rellax('.rellax')
        }
        
        let $fadeSliderElement = document.querySelector('.glide')
        if($fadeSliderElement) {
            var fadeSlider = new FadeSlider({})
            var $nextBtn = document.querySelector('.glide__arrows__next')
            var $prevBtn = document.querySelector('.glide__arrows__prev')

            $nextBtn.addEventListener('click', function(e) {
                fadeSlider.nextSlide()
                gaWrapper('Slider Interaction', 'SliderUI', 'Click', 'Next')
                
            })

            $prevBtn.addEventListener('click', function(e) {
                fadeSlider.previousSlide()
                gaWrapper('Slider Interaction', 'SliderUI', 'Click', 'Prev')
            })

            // Get a reference to an element
            var square = document.querySelector('.glide')

            // Create a manager to manager the element
            window.Hammer = Hammer.default
            var manager = new Hammer.Manager(square)

            // Create a recognizer
            var Swipe = new Hammer.Swipe({
                direction: Hammer.DIRECTION_HORIZONTAL
            })

            // Add the recognizer to the manager
            manager.add(Swipe)

            // Subscribe to a desired event
            manager.on('swipeleft', function(e) {
                fadeSlider.nextSlide()
                gaWrapper('Slider Interaction', 'SliderUI', 'Swipe', 'Next')
            })

            manager.on('swiperight', function(e) {
                fadeSlider.previousSlide()
                gaWrapper('Slider Interaction', 'SliderUI', 'Swipe', 'Prev')
            })
        }
        
        

        var instances = []
        var handle360lazyLoading = (entries) => {
            entries.forEach(entry => {
                var tag = entry.target.getAttribute('data-360-ref')
                if (entry.intersectionRatio > 0 && instances.indexOf(tag) === -1) {
                    instances.push(tag)
                    var slider360 = new Slider360({
                        container : entry.target,
                        tag : tag
                    })
                    slider360.preload()
                }
            })
        }
        
        var targets = document.querySelectorAll('[data-360-ref]')
        var observer360Views = new IntersectionObserver(handle360lazyLoading)

        targets.forEach(item => {
            observer360Views.observe(item);
        })        
        
    }
    
})