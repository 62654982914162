import * as Hammer from 'hammerjs'
import gaWrapper from './ga-events'

const CURRENT_IMAGE_CLASS = 'crt-img-360'
const PREVIOUS_IMAGE_CLASS = 'prev-img-360'

var rangeMapper = function (input, in_min, in_max, out_min, out_max) {
    return (input - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export default class Slider360 {
    constructor(options) {
        this.ready = false,
        // Tells the app if the user is dragging the pointer
        this.dragging = false,
        // Stores the pointer starting X position for the pointer tracking
        this.pointerStartPosX = 0,
        // Stores the pointer ending X position for the pointer tracking
        this.pointerEndPosX = 0,
        // Stores the distance between the starting and ending pointer X position in each time period we are tracking the pointer
        this.pointerDistance = 0,

        // The starting time of the pointer tracking period
        this.monitorStartTime = 0,
        // The pointer tracking time duration
        this.monitorInt = 10,
        // A setInterval instance used to call the rendering function
        this.ticker = 0,
        // Sets the speed of the image sliding animation
        this.speedMultiplier = 4,

        // Stores the total amount of images we have in the sequence
        this.totalFrames = 36,
        // The current frame value of the image slider animation
        this.currentFrame = 0,
        // Stores all the loaded image objects
        this.frames = [],
        // The value of the end frame which the currentFrame will be tweened to during the sliding animation
        this.endFrame = 0,
        // We keep track of the loaded images by increasing every time a new image is added to the image slider
        this.loadedImages = 0,

        // Caching DOM element references
        this.$container = options.container,
        this.$images = this.$container.querySelector('.c-360-images-wrapper')

        this.$loading = this.$container.querySelector('.c-360-loading')
        this.$loadingValue = this.$loading.querySelector('span')
        this.$loadingBar = this.$loading.querySelector('strong')
        

        this.$indicator = this.$container.querySelector('.c-360-indicator')

        this.tag = options.tag

        // TODO: temporarily test with jpg
        this.extension = 'jpg'

        this.$container.addEventListener('mousedown', (event) => {
            event.preventDefault();
            // Stores the pointer x position as the starting position
            this.pointerStartPosX = this.getPointerEvent(event).pageX;
            // Tells the pointer tracking function that the user is actually dragging the pointer and it needs to track the pointer changes
            this.dragging = true;
            this.removeIndicator()
        })
        
        /**
        * Adds the jQuery "mouseup" event to the document. We use the document because we want to let the user to be able to drag
        * the mouse outside the image slider as well, providing a much bigger "playground".
        */
       document.addEventListener('mouseup', (event) => {
            // Prevents the original event handler behaciour
            event.preventDefault();
            // Tells the pointer tracking function that the user finished dragging the pointer and it doesn't need to track the pointer changes anymore
            this.dragging = false;
        })
        
        /**
        * Adds the jQuery "mousemove" event handler to the document. By using the document again we give the user a better user experience
        * by providing more playing area for the mouse interaction.
        */
       this.$container.addEventListener('mousemove', (event) => {
            
            // Prevents the original event handler behaciour
            event.preventDefault();
            // Starts tracking the pointer X position changes
            this.trackPointer(event);
        })
        


        // Create a manager to manager the element
        window.Hammer = Hammer.default
        var manager = new Hammer.Manager(this.$container)

        // Create a recognizer
        var Panning = new Hammer.Pan({
            direction: Hammer.DIRECTION_HORIZONTAL
        })

        // Add the recognizer to the manager
        manager.add(Panning)

        // Subscribe to a desired event
        manager.on('panstart', function(e) {
            // Prevents the original event handler behaciour
            event.preventDefault();
            // Stores the pointer x position as the starting position
            this.pointerStartPosX = this.getPointerEvent(event).pageX;
            // Tells the pointer tracking function that the user is actually dragging the pointer and it needs to track the pointer changes
            this.dragging = true;
            this.removeIndicator()
        }.bind(this))

        manager.on('panmove', function(e) {
            // Prevents the original event handler behaciour
            event.preventDefault();
            // Starts tracking the pointer X position changes
            this.trackPointer(event);
        }.bind(this))

        manager.on('panend', function(e) {
            // Prevents the original event handler behaciour
            event.preventDefault();
            // Tells the pointer tracking function that the user finished dragging the pointer and it doesn't need to track the pointer changes anymore
            this.dragging = false;
        }.bind(this))

    }

    preload() {
        for(var i = 1; i <= this.totalFrames; i++) {
            this.loadImage(i)
        }
    }

    
    loadImage(index) {
        // The first one is already in DOM, handle only the others
        if(index !== 1) {
            // Creates a new <li>
            let li = document.createElement("li")
            
            // Generates the image file name using the incremented "loadedImages" variable
            let imageName = `/static/${this.tag}_${index}.${this.extension}`
            let xsImageName = `/static/${this.tag}_${index}-xs.${this.extension}`
            
            /*
                Creates a new <img> and sets its src attribute to point to the file name we generated.
                It also hides the image by applying the "previous-image" CSS class to it.
                The image then is added to the <li>.
            */
            let image = document.createElement('img')
            // image.src = xsImageName
            image.setAttribute('srcset', `${xsImageName} 500w, ${imageName} 1000w`)
            image.setAttribute('sizes', `(max-width: 375px) 320px,
            1000px`)

            
            image.classList.add(PREVIOUS_IMAGE_CLASS)
            
            
            li.appendChild(image)

            
            // We add the newly added image object (returned by jQuery) to the "frames" array.
            this.frames.push(image)
            // We add the <li> to the <ol>
            this.$images.appendChild(li)
            /*
                Adds the "load" event handler to the new image.
            */
            image.onload = () => {
                this.loadedImages++;
                
                let loadedPercent = Math.round(rangeMapper(this.loadedImages, 1, 36, 1, 100))
                this.$loadingValue.innerHTML = loadedPercent + '%'
                this.$loadingBar.style.width = loadedPercent + '%'

                if (this.loadedImages == this.totalFrames) {
                    // ...if so, it makes the first image in the sequence to be visible by removing the "previous-image" class and applying the "current-image" on it
                    this.frames[0].classList.remove(PREVIOUS_IMAGE_CLASS)
                    this.frames[0].classList.add(CURRENT_IMAGE_CLASS)
                    this.showThreesixty()
                }
            }
        } else {
            // Just get the initial image from DOM, and then insert it in the beginning of the array
            let placeholder = this.$images.querySelector('.initial-360-placeholder')
            this.frames.unshift(placeholder)
            this.loadedImages++;
        }
    }
    
    showThreesixty () {
        this.$loading.classList.add('c-360-loading--finished')
        this.$indicator.style.display = 'block'
        setTimeout(() => {
            this.$indicator.classList.add('c-360-indicator--shown')
        }, 300)
        
		// this.$images.style.display = 'block'
		// Sets the "ready" variable to true, so the app now reacts to user interaction 
		this.ready = true
		// Sets the endFrame to an initial value...
		this.endFrame = -36
		// ...so when the animation renders, it will initially take 4 complete spins.
        this.refresh()
    }

    removeIndicator() {
        this.$indicator.classList.remove('c-360-indicator--shown')
        this.$indicator.addEventListener('transitionend', () => {
            this.$indicator.remove()
            gaWrapper('360 view interaction', '360 Model', 'Rotate', '')
        })   
        
    }
    
    refresh () {
		// If the ticker is not running already...
		if (this.ticker === 0) {
			// Let's create a new one!
			this.ticker = requestAnimationFrame(this.render.bind(this))
		}
    }
    
    render () {
		// The rendering function only runs if the "currentFrame" value hasn't reached the "endFrame" one
        if(this.currentFrame !== this.endFrame) {
            window.isRendering = true
            requestAnimationFrame(this.render.bind(this))
			/*
				Calculates the 10% of the distance between the "currentFrame" and the "endFrame".
				By adding only 10% we get a nice smooth and eased animation.
				If the distance is a positive number, we have to ceil the value, if its a negative number, we have to floor it to make sure
				that the "currentFrame" value surely reaches the "endFrame" value and the rendering doesn't end up in an infinite loop.
			*/
			var frameEasing = this.endFrame < this.currentFrame ? Math.floor((this.endFrame - this.currentFrame) * 0.1) : Math.ceil((this.endFrame - this.currentFrame) * 0.1);
			// Sets the current image to be hidden
			this.hidePreviousFrame();
			// Increments / decrements the "currentFrame" value by the 10% of the frame distance
			this.currentFrame += frameEasing;
			// Sets the current image to be visible
            this.showCurrentFrame();
            
		} else {
            // If the rendering can stop, we stop and clear the ticker
            window.isRendering = false
            cancelAnimationFrame(this.ticker);
			this.ticker = 0;
		}
    }
    
    hidePreviousFrame() {
		/*
			Replaces the "current-image" class with the "previous-image" one on the image.
			It calls the "getNormalizedCurrentFrame" method to translate the "currentFrame" value to the "totalFrames" range (1-180 by default).
        */
       let idx = this.getNormalizedCurrentFrame()
       this.frames[idx].classList.remove(CURRENT_IMAGE_CLASS)
       this.frames[idx].classList.add(PREVIOUS_IMAGE_CLASS)
    }
    
    showCurrentFrame() {
		/*
			Replaces the "current-image" class with the "previous-image" one on the image.
			It calls the "getNormalizedCurrentFrame" method to translate the "currentFrame" value to the "totalFrames" range (1-180 by default).
        */
       let idx = this.getNormalizedCurrentFrame()
       this.frames[idx].classList.remove(PREVIOUS_IMAGE_CLASS)
       this.frames[idx].classList.add(CURRENT_IMAGE_CLASS)
    }
    
    getNormalizedCurrentFrame() {
		var c = -Math.ceil(this.currentFrame % this.totalFrames);
		if (c < 0) c += (this.totalFrames - 1);
		return c;
    }



    getPointerEvent(event) {
		return event.targetTouches ? event.targetTouches[0] : event;
    };
    
    trackPointer(event) {
		var userDragging = this.ready && this.dragging ? true : false;

		if(userDragging) {
			
			// Stores the last x position of the pointer
			this.pointerEndPosX = this.getPointerEvent(event).pageX;

			// Checks if there is enough time past between this and the last time period of tracking
			if(this.monitorStartTime < new Date().getTime() - this.monitorInt) {
				// Calculates the distance between the pointer starting and ending position during the last tracking time period
				this.pointerDistance = this.pointerEndPosX - this.pointerStartPosX;
				// Calculates the endFrame using the distance between the pointer X starting and ending positions and the "speedMultiplier" values
				this.endFrame = this.currentFrame - Math.ceil((this.totalFrames - 1) * this.speedMultiplier * (this.pointerDistance / this.$container.offsetWidth));
				// Updates the image slider frame animation
                this.refresh();

				// restarts counting the pointer tracking period
				this.monitorStartTime = new Date().getTime();
				// Stores the the pointer X position as the starting position (because we started a new tracking period)

				this.pointerStartPosX = this.getPointerEvent(event).pageX
			}
		} else {
			return;
		}
	};
     
}