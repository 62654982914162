import {addWheelListener} from 'wheel'
import Slider360 from './360-slider'
import animateBranding from './brading-animation'
import gaWrapper from './ga-events'

const ACTIVE_BODY_CLASS = 'section-transition-enabled'
var triggers360Mappings = [
    {
        id : 'protv',
        slide : [3,4]
    },
    {
        id : 'hotnews',
        slide : [4,5]
    },
    {
        id : 'europafm',
        slide : [5,6]
    },
    {
        id : 'recorder',
        slide : [6,7]
    },
    {
        id : 'tnr',
        slide : [7,8,9]
    }
]


window.sectionTransitioning = false
var $mainWrapper = document.querySelector('.wrapper')
$mainWrapper.addEventListener('transitionend', (ev) => {
    if(ev.target.className === 'wrapper') {
        window.sectionTransitioning = false
    }
})

function getAverage(elements, number) {
    var sum = 0;

    //taking `number` elements from the end to make the average, if there are not enought, 1
    var lastElements = elements.slice(Math.max(elements.length - number, 1));

    for(var i = 0; i < lastElements.length; i++) {
        sum = sum + lastElements[i];
    }

    return Math.ceil(sum / number);
}


export default class SectionTransition {
    constructor(options) {
        // this.$wrapper = options.wrapper || document.querySelector('.wrapper')
        this.$sections = Array.from(options.sections || document.querySelectorAll('section.section'))
        this.$anchors = options.anchors || document.querySelectorAll('.section-anchor')

        this.$subSectionNav = options.subSectionsNav || document.querySelector('.sub-section-nav')
        this.$subAnchorsSets = Array.from(options.subAnchorsSets || this.$subSectionNav.querySelectorAll('.sub-section-nav__set'))
        this.$subAnchors = Array.from(options.subAnchors || this.$subSectionNav.querySelectorAll('[data-ref-id]'))

        this.$brandingElement = options.brandingElement || document.querySelector('.branding-animation')
        this.$brandingTitle = document.querySelector('.branding-title')


        this.$body = document.querySelector('body')

        window.sectionTransitioning = false
        this.sectionHeight = window.innerHeight
        this.currentPosition = 0
        this.currentSectionIndex = 0
        this.currentSectionLabel = '/'
        this.totalSections = this.$sections.length

        this.scrollings = []
        this.init()
    }
    

    init() {
        window.scrollTo(0, 0)
        this.$body.classList.add(ACTIVE_BODY_CLASS)
        this.sectionHeight = window.innerHeight
        this.prevTime = new Date().getTime()

        // Adapt the existing SVG in order to avoid duplication
        let newBrandingAnimation = this.$brandingElement.cloneNode(true)
        this.$brandingTitle.appendChild(newBrandingAnimation)
        this.$brandingElement.remove()
        let $svgLogo = this.$brandingTitle.querySelector('svg')
        $svgLogo.setAttribute('width', '340px')
        $svgLogo.setAttribute('height', '246px')
        this.animationInstance = animateBranding()
        
        addWheelListener(window, this.wheelHandler.bind(this))

        for(let anchor of this.$anchors) {
            anchor.addEventListener('click', this.handleNavigation.bind(this))
        }

        window.onpopstate = function(event) {
            if(event.state) {
                this.handleNavigation(null, event.state.target)
            }
        }.bind(this);
        

        if(window.location.hash) {
            this.handleNavigation(null, window.location.hash)
        } else {
            history.replaceState({target : '#manifesto'}, 'Manifest', '/')
        }

    }

    updateSectionsMenu(labelSection) {
            for(let anchor of this.$anchors) {
                anchor.classList.remove('section-anchor--active')
                if(anchor.getAttribute('href') === labelSection) {
                    anchor.classList.add('section-anchor--active')
                }
            }
    }

    updateSubSectionsNav(labelSection) {
            this.$subSectionNav.classList.add('sub-section-nav--shown')
            for(let anchorsSet of this.$subAnchorsSets) {
                anchorsSet.classList.remove('sub-section-nav__set--active')
            }

            let $sectionShown = this.$subAnchorsSets.find((item) => {
                return item.dataset.sectionParentId === labelSection
            })

            $sectionShown.classList.add('sub-section-nav__set--active')
    }

    updateSubSectionsBullets(idx) {
        for(let subAnchor of this.$subAnchors) {
            subAnchor.classList.remove('section-anchor--active')
        }

        this.$subAnchors[idx].classList.add('section-anchor--active')
    }

    handleHistory(idx) {
        let historyBySlide
        let labelSection
        if(idx >= 0 && idx < 4) {
            historyBySlide = '/'
            labelSection = '#manifesto'
        }

        if(idx >= 4 && idx < 9) {
            historyBySlide = '/#lasam-invidia-deoparte'
            labelSection = '#lasam-invidia-deoparte'
        }

        if(idx >= 9 && idx < this.totalSections) {
            historyBySlide = '/#invidia-la-romani'
            labelSection = '#invidia-la-romani'

            let lazyImage = this.$sections[idx].querySelector('.lozad-big')
            if(lazyImage) {
                lozadObserver.triggerLoad(lazyImage)
            }
            
        }

        if(idx === 3) {
            this.animationInstance.restart()
        }

        // check if we have a slide where we need to create a new instance of 360 slider
        for(let trigger of triggers360Mappings) {
            if(trigger.slide.includes(idx) && !trigger.isInitiated) {
                let $container360 = document.querySelector(`.c-360-container[data-360-ref='${trigger.id}']`)
                
                if($container360) {
                    var slider360 = new Slider360({
                        container : $container360,
                        tag : trigger.id
                    })
                    slider360.preload()
                    trigger.isInitiated = true
                }
            }
        }

        if(historyBySlide !== this.currentSectionLabel) {
            this.currentSectionLabel = historyBySlide
            history.replaceState({},'', historyBySlide)
            this.updateSectionsMenu(labelSection)
            this.updateSubSectionsNav(labelSection)
        }

        gaWrapper('Full screen slider interaction', 'Sections', 'View', idx)

    }

    handleNavigation(e, targetLabel) { 
        let sectionTargetId
        if(e) {
            e.preventDefault()
            sectionTargetId = e.target.getAttribute('href') || e.target.dataset.refId
        } else {
            sectionTargetId = targetLabel
        }
        
        let sectionElementTargetIndex
        for(let section of this.$sections) {
            if(section.dataset.sectionId === sectionTargetId) {
                sectionElementTargetIndex = this.$sections.indexOf(section)
                break
            }
        }

        this.handleTransition(null, sectionElementTargetIndex)
        
    }

    handleTransition(direction, idx) {
        window.sectionTransitioning = true
        let futurePosition
        
        if(typeof idx === 'undefined') {
            if(direction === 'down' && this.currentSectionIndex < this.totalSections - 1) {
                this.currentSectionIndex++
            }
    
            if(direction === 'up' && this.currentSectionIndex > 0) {
                this.currentSectionIndex--
            }
        } else {
            this.currentSectionIndex = idx
        }
        
        futurePosition = this.currentSectionIndex * this.sectionHeight

        if(futurePosition !== this.currentPosition) {
            this.handleHistory(this.currentSectionIndex)
            this.updateSubSectionsBullets(this.currentSectionIndex)
            let translate3d = `translate3d(0, -${futurePosition}px, 0)`
            $mainWrapper.style.transform = translate3d
            this.currentPosition = futurePosition
        }
        
    }

    
    wheelHandler(e) {
        if(!window.isRendering && !window.sectionTransitioning) {
            var curTime = new Date().getTime();
        
            var value = e.wheelDelta || -e.deltaY || -e.detail;
            var delta = Math.max(-1, Math.min(1, value));

            var horizontalDetection = typeof e.wheelDeltaX !== 'undefined' || typeof e.deltaX !== 'undefined';
            var isScrollingVertically = (Math.abs(e.wheelDeltaX) < Math.abs(e.wheelDelta)) || (Math.abs(e.deltaX ) < Math.abs(e.deltaY) || !horizontalDetection);

            //Limiting the array to 150 (lets not waste memory!)
            if(this.scrollings.length > 149) {
                this.scrollings.shift();
            }

            //keeping record of the previous scrollings
            this.scrollings.push(Math.abs(value));


            //time difference between the last scroll and the current one
            var timeDiff = curTime - this.prevTime;
            this.prevTime = curTime;

            //haven't they scrolled in a while?
            //(enough to be consider a different scrolling action to scroll another section)
            if(timeDiff > 200) {
                //emptying the array, we dont care about old scrollings for our averages
                this.scrollings = [];
            }
            
            var averageEnd = getAverage(this.scrollings, 10);
            var averageMiddle = getAverage(this.scrollings, 70);
            var isAccelerating = averageEnd > averageMiddle;
            
            if(isAccelerating && isScrollingVertically) {
                    window.sectionTransitioning = true
                    let wDelta = delta < 0 ? 'down' : 'up';
                    this.handleTransition(wDelta)
            }
        }
    }

}