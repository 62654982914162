window.dataLayer = window.dataLayer || [];


    var gaWrapper = function(eventName, category, action, label) {
        window.dataLayer.push({
            'event': eventName,
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label
        });
    }


export default gaWrapper