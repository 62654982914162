import anime from 'animejs'

var animateBranding = () => {
    var logoAnimation = anime({
        targets: '.animated-logo-initial',
        d: 'M49.57,50.4746888 C47.9256399,34.0560943 46.2653222,9.81548091 43.3923026,0.717885062 C42.5698829,-1.88635821 39.56,5.41244813 39.22,6.10954357 C33.52,17.8008299 36.945,40.8547718 32.725,55.7128631 C30.215,41.6962656 31.75,14.6240664 22.87,4.15767635 C22.34,3.53526971 20.805,2.49326614 20.805,5.71120332 C20.805,19.8332867 19.14,35.7947729 19.14,52.5311203',
        easing: 'easeInQuart',
        duration: 300,
        direction: 'alternate',
        loop: 4,
        autoplay: false
    })

    return logoAnimation
}


export default animateBranding