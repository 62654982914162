import animateBranding from './brading-animation'

export default class FadeSlider  {
    constructor(options) {
        this.$container = options.container || document.querySelector('.glide')
        this.$track = options.track || document.querySelector('.glide__track')
        this.$slidesContainer = options.slidesContainer || document.querySelector('.glide__slides')

        this.$bgImagesWrapper = options.bgImage || document.querySelector('.glide__bgs')
        this.$bgImages = this.$bgImagesWrapper.querySelectorAll('.glide__bgs__item')

        this.$bgOverlay = options.bgOverlay || document.querySelector('.glide__overlay')
        this.$brandingElement = document.querySelector('.branding-animation')

        this.$nextArrow = document.querySelector('.glide__arrows__next')
        this.$prevArrow = document.querySelector('.glide__arrows__prev')

        this.currentPosition = 0
        this.currentSlideIndex = 0
        this.previousSlideIndex = 0
        this.isTransitioning = false
        this.autoplay = 0
        this.autoplayInterval = 4000

        this.build()

        this.animationInstance = animateBranding()
    }
    
    build() {
        this.slideWidth = this.$container.clientWidth
        this.totalSlides = this.$slidesContainer.querySelectorAll('.glide__slide').length
        
        setTimeout(() => {
            this.nextSlide()
        }, this.autoplayInterval / 2)
    }

    moveToSlide(i) {
        let futurePosition = i * this.slideWidth * -1
        this.moveTrackPosition(futurePosition)
        this.changeBackgrounds(i)

        if(!this.autoplay) {
            this.autoplay = setInterval(() => {
                this.nextSlide()
            }, this.autoplayInterval)
        } else {
            clearInterval(this.autoplay)
            this.autoplay = setInterval(() => {
                this.nextSlide()
            }, this.autoplayInterval)
        }
        
        if(i + 1 === this.totalSlides) {
            clearInterval(this.autoplay)
            this.autoplay = undefined

            this.$brandingElement.classList.add('branding-animation--visible')
            this.animationInstance.restart()
        } else {
            this.$brandingElement.classList.remove('branding-animation--visible')
        }

        // TODO: Build logic based on events
        if(i + 1 === this.totalSlides) {
            this.$nextArrow.classList.add('glide__arrows--na')
        } else {
            this.$nextArrow.classList.remove('glide__arrows--na')
        }

        if(i === 0){
            this.$prevArrow.classList.add('glide__arrows--na')
        } else {
            this.$prevArrow.classList.remove('glide__arrows--na')
        }
    }

    changeBackgrounds(i) {
        this.$bgImages[this.previousSlideIndex].classList.remove('glide__bgs__item--active')
        this.$bgOverlay.className = `glide__overlay glide__overlay__${i}`
        
        setTimeout(() => {
            this.$bgImages[i].classList.add('glide__bgs__item--animated')
            this.$bgImages[i].classList.add('glide__bgs__item--active')   
            this.isTransitioning = false 
        }, 260)
        
    }

    moveTrackPosition(pos) {
        let translate3d = `translate3d(${pos}px, 0, 0)`
        this.$slidesContainer.style.transform = translate3d
    }

    nextSlide() {
        if(this.currentSlideIndex < this.totalSlides - 1 && !this.isTransitioning) {
            this.isTransitioning = true
            this.previousSlideIndex = this.currentSlideIndex
            this.currentSlideIndex = this.currentSlideIndex + 1
            this.moveToSlide(this.currentSlideIndex)
        }
    }

    previousSlide() {
        if(this.currentSlideIndex > 0 && !this.isTransitioning) {
            this.isTransitioning = true
            this.previousSlideIndex = this.currentSlideIndex
            this.currentSlideIndex = this.currentSlideIndex - 1
            this.moveToSlide(this.currentSlideIndex)
        }
    }
}