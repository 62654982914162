import Headroom from 'headroom.js'

// Sticky header
var $header = document.querySelector(".app-header")
var headroom  = new Headroom($header, {
    offset : 100,
    tolerance : 0,
    tolerance : {
        up : 10,
        down : 0
    }
})
headroom.init()