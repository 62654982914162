import tingle from 'tingle.js'





var $videoPlayBtn = document.querySelector('[data-video-trigger]')

if($videoPlayBtn) {
    $videoPlayBtn.addEventListener('click', function() {
        let videoId = this.getAttribute('data-video-trigger')
    
        if(window.innerWidth > 992) {
            let embedCode = `<div class="aspect-ratio">
            <iframe "width="100%" height="auto" src="https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&modestbranding=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </div>`
    
            var modal = new tingle.modal({
                footer: false,
                stickyFooter: false,
                closeMethods: ['overlay', 'button', 'escape'],
                closeLabel: "Close",
                cssClass: [],
                onClose: function() {
                    modal.destroy();
                },
                beforeClose: function() {
                    return true
                }
            })
            modal.setContent(embedCode)
            modal.open()
        } else {
            window.location.replace(`https://www.youtube.com/watch?v=${videoId}`)
        }
        
    })
}
